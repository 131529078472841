/** @jsx jsx */
import { jsx } from "theme-ui"
import { MdLink, MdStar } from "react-icons/md"

import ShareMenu from "../../../components/share-menu"
import { AnchorButton } from "gatsby-interface"

const Meta = ({ starter, repoName, imageSharp, demo }) => (
  <div
    sx={t => ({
      display: `flex`,
      flexDirection: `column-reverse`,
      flexWrap: `wrap`,
      fontFamily: `heading`,
      minWidth: `320px`,
      p: 7,
      pt: 0,
      width: `100%`,
      [t.mediaQueries.phablet]: {
        flexDirection: `row`,
        flexWrap: `nowrap`,
        pb: 0,
      },
      [t.mediaQueries.desktop]: {
        px: 9,
        py: 0,
      },
    })}
  >
    {starter.stars || starter.lastUpdated ? (
      <div
        sx={t => ({
          display: `flex`,
          flexShrink: 0,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          mt: 7,
          [t.mediaQueries.phablet]: {
            pr: 5,
            justifyContent: `flex-start`,
          },
        })}
      >
        {starter.stars ? (
          <div>
            <span
              sx={t => ({
                alignItems: `center`,
                color: `text.secondary`,
                display: `inline-flex`,
                [t.mediaQueries.phablet]: { pr: 6 },
              })}
            >
              <MdStar />
              {` `}
              <span sx={{ color: `text.primary`, pl: 2 }}>{starter.stars}</span>
            </span>
          </div>
        ) : null}

        {starter.lastUpdated ? (
          <div>Updated {showDate(starter.lastUpdated)}</div>
        ) : null}
      </div>
    ) : null}

    <div
      sx={t => ({
        borderBottom: t => `1px solid ${t.colors.standardLine}`,
        display: `flex`,
        flexGrow: 1,
        flexWrap: `nowrap`,
        mt: 7,
        pb: 4,
        [t.mediaQueries.phablet]: {
          borderBottom: 0,
        },
      })}
    >
      <div
        sx={{
          pr: 5,
          pb: 5,
          whiteSpace: `nowrap`,
          overflow: `hidden`,
          textOverflow: `ellipsis`,
        }}
      >
        By{` `}
        <a
          sx={{
            "&&": {
              borderBottom: 0,
              color: `lilac`,
              cursor: `pointer`,
              fontFamily: `heading`,
              "&:hover": {
                color: `gatsby`,
              },
            },
          }}
          href={`https://github.com/${starter.owner}`}
        >
          {starter.owner}
        </a>
      </div>
      <div
        css={{
          flex: `2 0 auto`,
          textAlign: `right`,
          position: `relative`,
          zIndex: 1,
        }}
      >
        <div
          sx={{
            position: `absolute`,
            right: 0,
            top: 0,
            left: `auto`,
            zIndex: 1,
            display: `flex`,
          }}
        >
          <AnchorButton
            size="M"
            href={demo}
            sx={{ mr: 3 }}
            rightIcon={<MdLink />}
          >
            Visit demo
          </AnchorButton>
          <ShareMenu
            url={`https://github.com/${starter.githubFullName}`}
            title={`Check out ${repoName} on the @gatsbyjs Starter Showcase!`}
            image={imageSharp.localFile.childImageSharp.resize.src}
          />
        </div>
      </div>
    </div>
  </div>
)

function showDate(dt) {
  const date = new Date(dt)
  return date.toLocaleDateString(`en-US`, {
    month: `short`,
    day: `numeric`,
    year: `numeric`,
  })
}

export default Meta
