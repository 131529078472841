/** @jsx jsx */
import { jsx } from "theme-ui"
import Copy from "../../../components/copy"

const StarterInstallation = ({ repoName, repoUrl }) => {
  const content = `gatsby new ${repoName || `my-gatsby-project`} ${repoUrl}`
  return (
    <div
      sx={t => ({
        mx: 7,
        [t.mediaQueries.desktop]: {
          mx: 9,
          display: `grid`,
          gridTemplateRows: `auto auto`,
          gridRowGap: 3,
        },
      })}
    >
      <div sx={{ fontSize: 1, color: `text.secondary` }}>
        Install this starter locally:
      </div>
      <pre
        sx={{
          background: `code.bg`,
          p: 0,
        }}
      >
        <code
          sx={t => ({
            alignItems: `center`,
            display: `flex`,
            justifyContent: `space-between`,
            overflowWrap: `break-word`,
            p: 3,
            [t.mediaQueries.desktop]: {
              p: 4,
            },
            "&:before": {
              display: `none`,
            },
            "&:after": {
              display: `none`,
            },
          })}
        >
          {content}
          <Copy
            fileName="Install command"
            content={content}
            sx={{ borderRadius: 1, position: `relative` }}
          />
        </code>
      </pre>
    </div>
  )
}

export default StarterInstallation
