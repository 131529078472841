import React, { useState } from "react"
import { graphql } from "gatsby"

import Header from "../../components/header"
import Layout from "../../layouts"
import FooterLinks from "../../components/shared/footer-links"
import SiteScreenshotDisplay from "../../components/site-screenshot-display"
import PageMetadata from "../../components/page-metadata"
import StarterHeader from "./starter-details-sections/header"
import StarterMeta from "./starter-details-sections/meta"
import StarterSource from "./starter-details-sections/source"
import StarterInstallation from "./starter-details-sections/installation"
import StarterInfo from "./starter-details-sections/info"

const StarterPage = ({ data: { wpStarter } }) => {
  const [showAllDeps, setShowAllDeps] = useState(false)
  const {
    starterTags,
    starterFields: { description, features, repoUrl, url: demoUrl, screenshot },
    githubMetaFields,
  } = wpStarter

  // preprocessing of dependencies
  const { miscDependencies = [], gatsbyDependencies = [] } = githubMetaFields
  const allDeps = [
    ...gatsbyDependencies.map(([name]) => name),
    ...miscDependencies.map(([name]) => name),
  ]
  const shownDeps = showAllDeps ? allDeps : allDeps.slice(0, 15)
  const showMore = !showAllDeps && allDeps.length - shownDeps.length > 0

  const updateShowAllDeps = () => {
    setShowAllDeps(true)
  }

  return (
    <Layout>
      <Header />
      <main id={`reach-skip-nav`}>
        <div
          css={{
            alignItems: `center`,
            display: `flex`,
            flexDirection: `column`,
            margin: `0 auto`,
            maxWidth: 1080,
          }}
        >
          <PageMetadata
            title={`${githubMetaFields.name}: Gatsby Starter`}
            description={`Gatsby Starters: ${githubMetaFields.name}`}
            image={screenshot.localFile.childImageSharp.fluid}
          />
          <div css={{ width: `100%` }}>
            <StarterHeader stub={githubMetaFields.stub} />
            <div
              sx={{
                display: `flex`,
                flexDirection: [`column-reverse`, `column`],
              }}
            >
              <StarterMeta
                starter={githubMetaFields}
                repoName={githubMetaFields.name}
                imageSharp={screenshot}
                demo={demoUrl}
              />
              <SiteScreenshotDisplay
                imageSharp={screenshot.localFile.childImageSharp.fluid}
                alt={`Screenshot of ${githubMetaFields.name}`}
              />
            </div>
            <StarterSource repoUrl={repoUrl} starter={githubMetaFields} />
            <StarterInstallation
              repoName={githubMetaFields.name}
              repoUrl={repoUrl}
            />
            <StarterInfo
              tags={starterTags.nodes}
              description={description}
              features={features}
              allDeps={allDeps}
              shownDeps={shownDeps}
              showMore={showMore}
              updateShowAllDeps={updateShowAllDeps}
            />
            <FooterLinks />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default StarterPage

export const pageQuery = graphql`
  query StarterDetailsQuery($id: String!) {
    wpStarter(id: { eq: $id }) {
      ...StarterDetails
    }
  }

  fragment StarterDetails on WpStarter {
    id
    title
    starterFields {
      description
      features
      repoUrl
      url
      screenshot {
        id
        localFile {
          childImageSharp {
            id
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
            resize(
              width: 1500
              height: 1500
              cropFocus: CENTER
              toFormat: JPG
            ) {
              src
            }
          }
        }
      }
    }
    starterTags {
      nodes {
        id
        name
      }
    }
    githubMetaFields {
      slug
      stub
      name
      description
      stars
      lastUpdated
      owner
      githubFullName
      gatsbyMajorVersion
      allDependencies
      gatsbyDependencies
      miscDependencies
    }
  }
`
