/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

const SiteScreenshotDisplay = ({ imageSharp, alt, boxShadow = true }) => (
  <div className="screenshot" sx={{ position: `relative` }}>
    {imageSharp && (
      <Img
        fluid={imageSharp}
        alt={alt ? alt : ``}
        sx={{
          borderRadius: 1,
          mb: 4,
          transition: t => t.transitions.default,
          boxShadow: boxShadow ? `raised` : false,
        }}
      />
    )}
  </div>
)

export default SiteScreenshotDisplay
