/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { MdArrowBack } from "react-icons/md"

import { withTitleHover } from "../../shared/styles"

const Header = ({ stub }) => (
  <div
    className="starter-detail-header"
    sx={t => ({
      p: 7,
      [t.mediaQueries.phablet]: { pb: 0 },
      [t.mediaQueries.desktop]: { p: 9, pb: 0 },
    })}
  >
    <div sx={{ pb: 2 }}>
      <Link
        to={`/starters`}
        sx={{
          "&&": {
            fontSize: 1,
            borderBottom: 0,
            color: `link.color`,
            fontWeight: `body`,
            "&:hover": {
              color: `purple.60`,
            },
          },
          ...withTitleHover,
        }}
      >
        <MdArrowBack sx={{ mr: 2 }} />
        All Starters
      </Link>
    </div>
    <div>
      <h1 sx={{ m: 0, display: `inline-block` }}>{stub}</h1>
    </div>
  </div>
)

export default Header
