/** @jsx jsx */
import { jsx } from "theme-ui"
import { GoMarkGithub as GithubIcon } from "react-icons/go"
import CodesandboxIcon from "../../../assets/codesandbox.svg"
import NetlifyIcon from "../../../assets/netlify-grayscale.svg"

const TechWithIcon = ({ icon, height = `1.2em`, children }) => {
  return (
    <span sx={{ whiteSpace: `nowrap` }}>
      {children}
      &nbsp;
      <img
        src={icon}
        alt=""
        sx={{
          verticalAlign: `text-bottom`,
          height: `${height}`,
          m: 0,
        }}
      />
    </span>
  )
}

const Source = ({ repoUrl, starter }) => (
  <div
    sx={t => ({
      display: `flex`,
      borderTop: t => `1px solid ${t.colors.standardLine}`,
      fontFamily: `heading`,
      mx: 7,
      [t.mediaQueries.phablet]: { borderTop: 0 },
      [t.mediaQueries.desktop]: { mx: 9 },
    })}
  >
    {repoUrl && (
      <a
        href={repoUrl}
        sx={{
          alignItems: `center`,
          display: `flex`,
          lineHeight: `solid`,
          p: 5,
          pl: 0,
          "&&": {
            borderBottom: 0,
            color: `link.color`,
            cursor: `pointer`,
            fontWeight: `body`,
            "&:hover": {
              color: `purple.60`,
            },
          },
        }}
      >
        <GithubIcon
          sx={{
            mb: 0,
            mr: 3,
          }}
        />
        Source
      </a>
    )}
    <div
      sx={t => ({
        display: `none`,
        [t.mediaQueries.desktop]: {
          alignItems: `center`,
          display: `flex`,
          flex: 1,
          justifyContent: `flex-end`,
          p: 5,
          pl: 0,
        },
      })}
    >
      <span
        sx={{
          color: `text.secondary`,
          mr: 5,
        }}
      >
        Try this starter
      </span>
      <a
        href={`https://codesandbox.io/s/github/${starter.owner}/${starter.stub}`}
        sx={{
          "&&": {
            borderBottom: 0,
            mr: 5,
          },
        }}
      >
        <TechWithIcon icon={CodesandboxIcon}>CodeSandbox</TechWithIcon>
      </a>
      {` `}
      &nbsp;
      <a
        href={`https://app.netlify.com/start/deploy?repository=${repoUrl}`}
        sx={{
          "&&": {
            borderBottom: 0,
          },
        }}
      >
        <TechWithIcon icon={NetlifyIcon}>Netlify</TechWithIcon>
      </a>
    </div>
  </div>
)

export default Source
