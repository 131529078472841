/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { MdLaunch } from "react-icons/md"

const Info = ({
  allDeps,
  shownDeps,
  updateShowAllDeps,
  showMore,
  description,
  features,
  tags,
}) => (
  <div
    sx={t => ({
      p: 7,
      [t.mediaQueries.tablet]: {
        display: `grid`,
        gridRowGap: 7,
        gridColumnGap: 7,
        gridTemplateColumns: `auto 1fr`,
        p: 9,
      },
    })}
  >
    <h2 sx={styles.headline}>Tags</h2>
    <div sx={styles.content}>{tags.map(tag => tag.name).join(`, `)}</div>

    <h2 sx={styles.headline}>Description</h2>
    <div sx={styles.content}>{description}</div>

    <h2 sx={styles.headline}>Features</h2>
    <div sx={styles.content}>
      {features ? (
        <ul sx={{ mt: 0 }}>
          {features
            .split("\n")
            .filter(Boolean)
            .map((f, i) => (
              <li key={i}>{f}</li>
            ))}
        </ul>
      ) : (
        `No features`
      )}
    </div>

    <h2 sx={styles.headline}>Dependencies</h2>

    <div>
      <div
        sx={t => ({
          display: `grid`,
          [t.mediaQueries.desktop]: {
            gridTemplateColumns: `repeat(3, 1fr)`,
            gridGap: 6,
          },
        })}
      >
        {shownDeps &&
          shownDeps.map(dep =>
            // gatsby-cypress is a helper plugin and not shown inside our plugins section
            // for that reason we are excluding it from our list of plugins
            /^gatsby-/.test(dep) && dep !== `gatsby-cypress` ? (
              <div key={dep}>
                <Link to={`/packages/${dep}`}>{dep}</Link>
              </div>
            ) : (
              <div key={dep}>
                <a href={`https://npm.im/${dep}`}>
                  {`${dep} `}
                  <MdLaunch />
                </a>
              </div>
            )
          )}
        {showMore && (
          <button sx={styles.showMoreButton} onClick={updateShowAllDeps}>
            {`Show ${allDeps.length - shownDeps.length} more`}
          </button>
        )}
      </div>
    </div>
  </div>
)

export default Info

const styles = {
  headline: t => ({
    color: `text.secondary`,
    fontWeight: `normal`,
    fontSize: 3,
    mt: 0,
    mb: 3,
    [t.mediaQueries.tablet]: {
      mb: 0,
    },
  }),
  content: t => ({
    pb: 9,
    [t.mediaQueries.tablet]: { pb: 0 },
  }),
  showMoreButton: _theme => ({
    backgroundColor: `gatsby`,
    border: 0,
    borderRadius: 1,
    cursor: `pointer`,
    fontFamily: `heading`,
    fontWeight: `bold`,
    py: 2,
    px: 5,
    "&&": {
      borderBottom: `none`,
      color: `white`,
    },
  }),
}
